<!-- eslint-disable prettier/prettier -->
<!-- eslint-disable no-unused-vars -->
<!-- eslint-disable no-unused-vars -->
<template>
  <!-- MAP - START -->

  <div
    class="template-calendar"
    ref="templatecalendar"
    :style="{ visibility: showCalendar }"
  >
    <icon
      icon="#cx-app1-arrow-left"
      class="back-to-main-page"
      v-on:click="showMainDisplay"
      v-on:touchend="showMainDisplay"
    ></icon>

    <div class="display-calendar-header">
      <p v-if="display">{{ display.today }}</p>
      <p v-if="display">{{ display.tomorrow }}</p>
    </div>
    <div class="display-calendar-content" ref="calendarcontent">
      <div
        v-if="display"
        :class="[
          'calendar-qr-code',
          status.class == 'checkin' ? 'calendar-qr-code-checkin' : '',
          status.class == 'busy' || status.class == 'declined'
            ? 'calendar-qr-code-busy'
            : ''
        ]"
      >
        <img :src="display.qrcode" />
      </div>
      <!-- TABLE: TODAY MORNING - START -->
      <div class="display-calendar-table" v-if="display && display.calendar">
        <div
          :from="item.from"
          :to="item.to"
          class="display-calendar-item"
          :class="[
            item.meeting
              ? 'display-calendar-table-meeting'
              : 'display-calendar-table-no-meeting',
            item.start ? 'display-calendar-item-start' : '',
            item.end ? 'display-calendar-item-end' : ''
          ]"
          v-for="item in display.calendar.today_morning"
          :key="item.from"
        >
          <div class="display-calendar-item-info">
            <p class="display-calendar-times" v-if="item.times">
              {{ item.times }}
            </p>
            <p class="display-calendar-subject" v-if="item.subject">
              {{ item.subject }}
            </p>
            <p class="display-calendar-host" v-if="item.host">
              {{ item.host }}
            </p>
          </div>
          <div
            v-if="display.meeting && item.meeting == display.meeting.id"
            :class="[
              status.class == 'checkin'
                ? 'display-calendar-item-status-checkin'
                : 'display-calendar-item-status-busy'
            ]"
          ></div>
        </div>
      </div>

      <!-- TABLE: TODAY MORNING - END -->
      <!-- TABLE: TODAY AFTERNOON - START -->

      <div class="display-calendar-table" v-if="display && display.calendar">
        <div
          :from="item.from"
          :to="item.to"
          class="display-calendar-item"
          :class="[
            item.meeting
              ? 'display-calendar-table-meeting'
              : 'display-calendar-table-no-meeting',
            item.start ? 'display-calendar-item-start' : '',
            item.end ? 'display-calendar-item-end' : ''
          ]"
          v-for="item in display.calendar.today_afternoon"
          :key="item.from"
        >
          <div class="display-calendar-item-info">
            <p class="display-calendar-times" v-if="item.times">
              {{ item.times }}
            </p>
            <p class="display-calendar-subject" v-if="item.subject">
              {{ item.subject }}
            </p>
            <p class="display-calendar-host" v-if="item.host">
              {{ item.host }}
            </p>
          </div>
          <div
            v-if="display.meeting && item.meeting == display.meeting.id"
            :class="[
              status.class == 'checkin'
                ? 'display-calendar-item-status-checkin'
                : 'display-calendar-item-status-busy'
            ]"
          ></div>
        </div>
      </div>

      <!-- TABLE: TODAY AFTERNOON - END -->
      <!-- TABLE: TOMORROW MORNING - START -->

      <div class="display-calendar-table" v-if="display && display.calendar">
        <div
          :from="item.from"
          :to="item.to"
          class="display-calendar-item"
          :class="[
            item.meeting
              ? 'display-calendar-table-meeting'
              : 'display-calendar-table-no-meeting',
            item.start ? 'display-calendar-item-start' : '',
            item.end ? 'display-calendar-item-end' : ''
          ]"
          v-for="item in display.calendar.tomorrow_morning"
          :key="item.from"
        >
          <div class="display-calendar-item-info">
            <p class="display-calendar-times" v-if="item.times">
              {{ item.times }}
            </p>
            <p class="display-calendar-subject" v-if="item.subject">
              {{ item.subject }}
            </p>
            <p class="display-calendar-host" v-if="item.host">
              {{ item.host }}
            </p>
          </div>
          <div
            v-if="display.meeting && item.meeting == display.meeting.id"
            :class="[
              status.class == 'checkin'
                ? 'display-calendar-item-status-checkin'
                : 'display-calendar-item-status-busy'
            ]"
          ></div>
        </div>
      </div>

      <!-- TABLE: TOMORROW MORNING - END -->
      <!-- TABLE: TOMORROW AFTERNOON - START -->

      <div class="display-calendar-table" v-if="display && display.calendar">
        <div
          :from="item.from"
          :to="item.to"
          class="display-calendar-item"
          :class="[
            item.meeting
              ? 'display-calendar-table-meeting'
              : 'display-calendar-table-no-meeting',
            item.start ? 'display-calendar-item-start' : '',
            item.end ? 'display-calendar-item-end' : ''
          ]"
          v-for="item in display.calendar.tomorrow_afternoon"
          :key="item.from"
        >
          <div class="display-calendar-item-info">
            <p class="display-calendar-times" v-if="item.times">
              {{ item.times }}
            </p>
            <p class="display-calendar-subject" v-if="item.subject">
              {{ item.subject }}
            </p>
            <p class="display-calendar-host" v-if="item.host">
              {{ item.host }}
            </p>
          </div>
          <div
            v-if="display.meeting && item.meeting == display.meeting.id"
            :class="[
              status.class == 'checkin'
                ? 'display-calendar-item-status-checkin'
                : 'display-calendar-item-status-busy'
            ]"
          ></div>
        </div>
      </div>

      <!-- TABLE: TOMORROW AFTERNOON - END -->
    </div>
  </div>

  <!-- VIDEO - START -->
  <div
    class="template-video"
    v-if="showVideo"
    v-on:click="showMainDisplay"
    v-on:touchend="showMainDisplay"
  >
    <div
      class="back-to-main-page-div"
      v-on:click="showMainDisplay"
      v-on:touchend="showMainDisplay"
    ></div>
    <iframe
      width="100%"
      height="100%"
      :src="videoUrl"
      title=""
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
    ></iframe>
  </div>

  <!-- VIDEO - END -->

  <!-- MAP - START -->
  <div class="template-map" :style="{ visibility: showMap }">
    <icon
      icon="#cx-app1-arrow-left"
      class="back-to-main-page"
      v-on:click="showMainDisplay"
      v-on:touchend="showMainDisplay"
    ></icon>
    <iframe
      ref="mapIframe"
      style="position: absolute; top: 0; left: 0; width: 100%; bottom: 0; border: none; overflow: auto"
      :src="mapUrl"
      frameborder="0"
      width="100%"
      height="100%"
    ></iframe>
    <div class="map-date-time" v-if="mapDate">
      <span class="map-time">{{ mapFrom }} - {{ mapTo }}</span>
      <span class="map-date">{{ mapDate }}</span>
    </div>
  </div>

  <!-- MAP - END -->

  <!-- VIDEOCONFERENCE - START -->

  <div class="template-videoconference" v-if="showVideoConference">
    <icon
      icon="#cx-app1-arrow-left"
      class="back-to-main-page"
      v-on:click="showMainDisplay"
      v-on:touchend="showMainDisplay"
    ></icon>
    <iframe
      style="position: absolute; top: 0; left: 0; width: 100%; bottom: 0; border: none; overflow: auto"
      :src="videoConferenceUrl"
      frameborder="0"
      width="100%"
      height="100%"
      allow="camera; microphone"
    ></iframe>
  </div>

  <!-- VIDEOCONFERENCE - END -->

  <div class="display-wrapper" v-if="showDisplay"  :style="[
            this.black == true
              ? { backgroundColor: '#000000' }
              : { backgroundColor: '#EFEFF4' }
          ]">
    <!-- ERROR SCREEN - START -->

    <div class="template-error" v-if="display.error">
      <img src="/img/logo@2x.3b392d37.png" />
      <div class="resource">{{ display.resource }}</div>
      <div class="message">{{ display.message }}</div>
    </div>

    <!-- ERROR SCREEN - END -->

    <!-- TEMPLATE 1 - START (also refers to template 3 and 4) -->

    <div
      class="template-1"
      v-if="!display.error && [1, 3, 4].includes(display.template)"
      :style="[
            this.black == true
              ? { backgroundColor: '#000000' }
              : { backgroundColor: '#EFEFF4' }
          ]"
    >
      <div class="left" ref="leftpart">
        <div class="resource" ref="resource" :style="[
            this.black == true
              ? { backgroundColor: '#262629' }
              : { backgroundColor: '#FFFFFF' }
          ]">
          <p class="name" :style="[
            this.black == true
              ? { color: '#FFFFFF' }
              : { color: '#373F4E' }
          ]">{{ display.resource.name }}</p>
          <p class="availability" :style="[
            this.black == true
              ? { color: '#C2C2C2' }
              : { color: '#737373' }
          ]">{{ display.status.message }}</p>
        </div>
        <div
          class="meeting"
          :style="[
            display.meeting
              ? { visibility: 'visible' }
              : { visibility: 'hidden' },
            this.black == true
              ? { backgroundColor: '#262629' }
              : { backgroundColor: '#FFFFFF' }
          ]"
        >
          <div class="info" v-if="display.meeting">
            <p class="subject" v-if="display.meeting.subject" :style="[
            this.black == true
              ? { color: '#FFFFFF' }
              : { color: '#373F4E' }
          ]">
              {{ display.meeting.subject }}
            </p>
            <p class="host" :style="[
            this.black == true
              ? { color: '#C2C2C2' }
              : { color: '#737373' }
          ]">{{ display.meeting.host }}</p>
          </div>
          <div
            class="times"
            ref="times"
            v-if="display.meeting && display.meeting.from && display.meeting.to"
            :style="[
            this.black == true
              ? { backgroundColor: '#4B4B4E' }
              : { backgroundColor: '#EFEFF4' }
          ]"
          >
            <div class="clock" :style="[
            this.black == true
              ? { fill: '#C2C2C2' }
              : { fill: '#737373' }
          ]"><icon icon="#cx-app1-time"></icon></div>
            <p class="time" :style="[
            this.black == true
              ? { color: '#A3A3A3' }
              : { color: '#A3A3A3' }
          ]">{{ display.meeting.from }}</p>
            <p class="time" :style="[
            this.black == true
              ? { color: '#A3A3A3' }
              : { color: '#A3A3A3' }
          ]">{{ display.meeting.to }}</p>
          </div>
        </div>
        <div class="options" :style="[display.template == 1 ? 'align-items: center' : '']">
          <div class="logo" id="logo" ref="logo"  :style="[
            this.black == true
              ? { backgroundColor: '#262629' }
              : { backgroundColor: '#FFFFFF' }
          ]">
            <div class="clebex-logo" v-if="!display.logo">
              <img :src="[ this.black == true ? require('@/assets/logowhite@2x.png') : '/img/logo@2x.3b392d37.png']" />
            </div>
            <div class="company-logo" v-if="display.logo">
              <img :src="display.logo" />
            </div>
            <div class="powered-by-clebex" v-if="display.logo && black == false">
              <powered-by-clebex />
            </div>
            <div class="powered-by-clebex" v-if="display.logo && black == true">
              <powered-by-clebex-white />
            </div>
          </div>
          <div class="next-meetings" ref="nextmeetings" v-if="[3, 4].includes(display.template)" :style="[
            this.black == true
              ? { backgroundColor: '#262629' }
              : { backgroundColor: '#FFFFFF' }
          ]">
            <div class="next-meetings-row" v-for="meeting in display.next_meetings" :key="meeting.id">
              <div class="next-meetings-column next-meeting-date" :style="[
            this.black == true
              ? { color: '#FFFFFF' }
              : { color: '#373F4E' }
          ]">{{ meeting.date }}</div>
              <div class="next-meetings-column next-meeting-time" :style="[
            this.black == true
              ? { color: '#FFFFFF' }
              : { color: '#373F4E' }
          ]">{{ meeting.time }}</div>
              <div class="next-meetings-column next-meeting-subject" v-if="display.template == 3" :style="[
            this.black == true
              ? { color: '#FFFFFF' }
              : { color: '#373F4E' }
          ]">{{ meeting.subject }}</div>
              <div class="next-meetings-column next-meeting-host" v-if="display.template == 4" :style="[
            this.black == true
              ? { color: '#FFFFFF' }
              : { color: '#373F4E' }
          ]">{{ meeting.host }}</div>
            </div>
          </div>
          <div class="option-container" v-if="display.map && display.template == 1">
          <div
            class="map"
            ref="map"
            v-on:click="showMapDisplay"
            v-on:touchend="showMapDisplay"
          >
            <icon style="width: 10vh;" icon="#cx-app1-5d-maps"></icon>
            
          </div>
          <span class="option-text">{{ display.labels.map }}</span>
          </div>
          <div class="option-container" v-if="display.template == 1">
          <div
            class="calendar"
            ref="calendar"
            v-on:click="showCalendarDisplay"
            v-on:touchend="showCalendarDisplay"
          >
            <icon style="width: 10vh;" icon="#cx-men1-booking"></icon>
            
          </div>
          <span class="option-text">{{ display.labels.calendar }}</span>
          </div>
          <div class="option-container" v-if="display.video && display.template == 1">
          <div
            class="video"
            ref="video"
            v-on:click="showVideoDisplay"
            v-on:touchend="showVideoDisplay"
          >
            <icon style="width: 10vh;" icon="#cx-men1-countries"></icon>
          </div>
          <span class="option-text">{{ display.labels.video }}</span>
          </div>
          <div class="option-container" v-if="display.videoconference && display.template == 1">
          <div
            class="videoconference"
            ref="videoconference"
            v-on:click="showVideoConferenceDisplay"
            v-on:touchend="showVideoConferenceDisplay"
          >
            <icon style="width: 10vh;" icon="#cx-app1-parent"></icon>
          </div>
          <span class="option-text">{{ display.labels.videoconference }}</span>
          </div>
        </div>
      </div>
      <div class="right" ref="rightpart">
        <div class="status" :class="status.class" ref="status" :style="[ display.anonymous_checkin && display.status.code == 2 ? { cursor: 'pointer' } : { cursor: 'auto' } ]" @click="anonymousCheckin()">
          <div class="status-icon">
            <icon style="width: 13vh;" :icon="status.icon"></icon>
          </div>
          <div class="status-white" ref="statuswhite"></div>
          <div class="qr-code" ref="qrcode" :style="[
            this.black == true
              ? { backgroundColor: '#262629' }
              : { backgroundColor: '#FFFFFF' }
          ]">
            <img :src="display.qrcode" :style="[
            this.black == true
              ? { borderRadius: '9%' }
              : { borderRadius: '9%' }
          ]"/>
          </div>
        </div>
      </div>
    </div>

    <!-- TEMPLATE 1 - END -->

    <!-- TEMPLATE 2 - START -->

    <div class="template-2" v-if="!display.error && display.template == 2" :style="[
            this.black
              ? backgroundColor: 'black'
          ]">
      <div class="upper">
        <div class="resource-meeting">
          <div class="resource" ref="resource">
            <p class="name">{{ display.resource.name }}</p>
            <p class="availability">{{ display.status.message }}</p>
          </div>
          <div
            class="meeting"
            :style="[!display.meeting ? { visibility: 'hidden' } : '']"
          >
            <div class="info" v-if="display.meeting">
              <p class="subject">{{ display.meeting.subject }}</p>
              <p class="host">{{ display.meeting.host }}</p>
            </div>
            <div
              class="times"
              ref="times"
              v-if="
                display.meeting && display.meeting.from && display.meeting.to
              "
            >
              <div class="clock"><icon icon="#cx-app1-time"></icon></div>
              <p class="time">{{ display.meeting.from }}</p>
              <p class="time">{{ display.meeting.to }}</p>
            </div>
          </div>
        </div>

        <div class="options">
          <div class="option" v-if="display.map">
            <icon ref="map" v-on:click="showMapDisplay" v-on:touchend="showMainDisplay" icon="#cx-app1-5d-maps"></icon>
            <span>{{ display.labels.map }}</span>
          </div>
          <div class="option">
            <icon icon="#cx-men1-booking" ref="calendar" v-on:click="showCalendarDisplay" v-on:touchend="showCalendarDisplay"></icon>
            <span>{{ display.labels.calendar }}</span>
          </div>
          <div class="option" v-if="display.video">
            <icon icon="#cx-men1-countries" ref="video" v-on:click="showVideoDisplay" v-on:touchend="showMainDisplay"></icon>
            <span>{{ display.labels.video }}</span>
          </div>
          <div class="option" v-if="display.videoconference && display.template == 2">
            <icon icon="#cx-app1-parent" ref="videoconference" v-on:click="showVideoConferenceDisplay" v-on:touchend="showVideoConferenceDisplay"></icon>
            <span>{{ display.labels.videoconference }}</span>
          </div>
        </div>
      </div>
      <div class="lower">
        <div class="status" :class="status.class" :style="[ display.anonymous_checkin && display.status.code == 2 ? { cursor: 'pointer' } : { cursor: 'auto' } ]" @click="anonymousCheckin()">
          <div class="logo">
            <div class="clebex-logo" v-if="!display.logo">
              <img src="/img/logo@2x.3b392d37.png" />
            </div>
            <div class="company-logo" v-if="display.logo">
              <img :src="display.logo" />
            </div>
            <div class="powered-by-clebex" v-if="display.logo">
              <powered-by-clebex />
            </div>
          </div>
          <div class="status-icon">
            <icon :icon="status.icon"></icon>
          </div>
          <div class="qr-code" ref="qrcode">
            <img :src="display.qrcode" />
          </div>
        </div>
      </div>
    </div>

    <!-- TEMPLATE 2 - END -->
    <!-- TEMPLATE 5 - START -->

        <div
      class="template-5"
      v-if="!display.error && [5].includes(display.template)"
    >
      <div class="upper">
      <div class="left" ref="leftpart">
        <div class="resource" ref="resource" :style="[
            this.black == true
              ? { backgroundColor: '#262629' }
              : { backgroundColor: '#FFFFFF' }
          ]">
          <p class="name" :style="[
            this.black == true
              ? { color: '#FFFFFF' }
              : { color: '#373F4E' }
          ]">{{ display.resource.name }}</p>
          <p class="availability" :style="[
            this.black == true
              ? { color: '#C2C2C2' }
              : { color: '#737373' }
          ]">{{ display.status.message }}</p>
        </div>
        <div
          class="meeting"
          :style="[
            display.meeting
              ? { visibility: 'visible' }
              : { visibility: 'hidden' },
            this.black == true
              ? { backgroundColor: '#262629' }
              : { backgroundColor: '#FFFFFF' }
          ]"
        >
          <div class="info" v-if="display.meeting">
            <p class="subject" v-if="display.meeting.subject" :style="[
            this.black == true
              ? { color: '#FFFFFF' }
              : { color: '#373F4E' }
          ]">
              {{ display.meeting.subject }}
            </p>
            <p class="host" :style="[
            this.black == true
              ? { color: '#C2C2C2' }
              : { color: '#737373' }
          ]">{{ display.meeting.host }}</p>
          </div>
          <div
            class="times"
            ref="times"
            v-if="display.meeting && display.meeting.from && display.meeting.to"
            :style="[
            this.black == true
              ? { backgroundColor: '#4B4B4E' }
              : { backgroundColor: '#EFEFF4' }
          ]"
          >
            <div class="clock" :style="[
            this.black == true
              ? { fill: '#C2C2C2' }
              : { fill: '#737373' }
          ]"><icon icon="#cx-app1-time"></icon></div>
            <p class="time" :style="[
            this.black == true
              ? { color: '#A3A3A3' }
              : { color: '#A3A3A3' }
          ]">{{ display.meeting.from }}</p>
            <p class="time" :style="[
            this.black == true
              ? { color: '#A3A3A3' }
              : { color: '#A3A3A3' }
          ]">{{ display.meeting.to }}</p>
          </div>
        </div>
      </div>
      <div class="right" ref="rightpart">
        <div class="status" :class="status.class" ref="status" :style="[ display.anonymous_checkin && display.status.code == 2 ? { cursor: 'pointer' } : { cursor: 'auto' } ]" @click="anonymousCheckin()">
          <div class="status-icon">
            <icon style="width: 13vh;" :icon="status.icon"></icon>
          </div>
          <div class="status-white" ref="statuswhite"></div>
          <div class="qr-code" ref="qrcode" :style="[
            this.black == true
              ? { backgroundColor: '#262629' }
              : { backgroundColor: '#FFFFFF' }
          ]">
            <img :src="display.qrcode" :style="[
            this.black == true
              ? { borderRadius: '5%' }
              : { borderRadius: '0%' }
          ]"/>
          </div>
        </div>
      </div>
    </div>
  <div class="lower">
          <div class="logo" id="logo" ref="logo" :style="[
            this.black == true
              ? { backgroundColor: '#262629' }
              : { backgroundColor: '#FFFFFF' }
          ]">
            <div class="clebex-logo" v-if="!display.logo">
              <img :src="[ this.black == true ? require('@/assets/logowhite@2x.png') : '/img/logo@2x.3b392d37.png']" />
            </div>
            <div class="company-logo" v-if="display.logo">
              <img :src="display.logo" />
            </div>
            <div class="powered-by-clebex" v-if="display.logo && black == false">
              <powered-by-clebex />
            </div>
            <div class="powered-by-clebex" v-if="display.logo && black == true">
              <powered-by-clebex-white />
            </div>
          </div>
          <div class="next-meetings" ref="nextmeetings" :style="[
            this.black == true
              ? { backgroundColor: '#262629' }
              : { backgroundColor: '#FFFFFF' }
          ]">
            <div class="next-meetings-row" v-for="meeting in display.next_meetings" :key="meeting.id">
              <div class="next-meetings-column next-meeting-date" :style="[
            this.black == true
              ? { color: '#FFFFFF' }
              : { color: '#373F4E' }
          ]">{{ meeting.date }}</div>
              <div class="next-meetings-column next-meeting-time" :style="[
            this.black == true
              ? { color: '#FFFFFF' }
              : { color: '#373F4E' }
          ]">{{ meeting.time }}</div>
              <div class="next-meetings-column next-meeting-time" :style="[
            this.black == true
              ? { color: '#FFFFFF' }
              : { color: '#373F4E' }
          ]">{{ meeting.subject }}</div>
              <div class="next-meetings-column next-meeting-host" :style="[
            this.black == true
              ? { color: '#FFFFFF' }
              : { color: '#373F4E' }
          ]">{{ meeting.host }}</div>
            </div>
          </div>
  </div>
</div>
    <!-- TEMPLATE 5 - END -->
  </div>
</template>

<script>
import httpServiceAuth, { getSubdomain } from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import PoweredByClebex from "@/components/global/PoweredByClebex";
import PoweredByClebexWhite from "@/components/global/PoweredByClebexWhite";
import { subscribe, unsubscribe } from "@/services/ws-service";
import { wsEvents } from "@/services/constants";
import { displayCrestron } from "@/services/crestron";
import { displayQbic } from "@/services/qbic";
import { displayIadea } from "@/services/iadea";
import mapMixin from "@/services/mixins/map/map";

export default {
  name: "Display",
  components: {
    PoweredByClebex,
    PoweredByClebexWhite
  },
  mixins: [mapMixin],
  data() {
    return {
      screensaver: null,
      screensaverTime: null,
      screensaverInterval: null,
      elementHeight: null,
      mapUrl: null,
      mapIframe: false,
      showMap: "hidden",
      showDisplay: null,
      showCalendar: "hidden",
      showVideo: null,
      showVideoConference: false,
      observer: null,
      display: null,
      template: 1,
      resize: false,
      connectorChangeChannel: null,
      companyProfileChannel: null,
      resourceChangeChannel: null,
      declarationChangeChannel: null,
      levelChangeChannel: null,
      status: {
        icon: "#cx-msg1-accepted-100x100",
        class: "accepted"
      },
      mapDate: null,
      mapFrom: null,
      mapTo: null,
      black: false
    };
  },
  async created() {
    // nothing
    await this.initialize();
    await this.subscribe();
  },
  async mounted() {},
  computed: {
    code() {
      const { code } = this.$route.params;
      return code;
    }
  },
  methods: {
    async initialize() {
      this.display = await this.getDisplayInfo();
      this.display = this.display.data;
      this.showDisplay = true;
      this.setTemplate();
      this.setStatus();

      if (!this.display.error) {
        await this.$nextTick(function() {
          this.setDisplaySize();
        });

        if (!this.resize) {
          window.addEventListener("resize", () => {
            this.setDisplaySize();
          });
          this.resize = true;
        }

        this.setMapUrl();
        this.displayHeartbeat();
        this.setScreensaver();
        this.setVideoconfrenceUrl();
      }
    },
    async subscribe() {
      if (this.display.error) return;
      this.connectorChangeChannel = subscribe(
        "connectors",
        [getSubdomain()],
        wsEvents.connectors.changed,
        this.connectorChangeCallback,
        true
      );

      this.companyProfileChannel = subscribe(
        "company-profile",
        [getSubdomain()],
        wsEvents.companyProfile.changed,
        this.companyProfileCallback,
        true
      );

      this.resourceChangeChannel = subscribe(
        "resource-functions",
        [getSubdomain(), this.display.resource.id],
        wsEvents.resourceChange.changed,
        this.resourceChangeCallback,
        true
      );
      this.declarationChangeChannel = subscribe(
        "declaration-changed",
        [getSubdomain(), this.display.resource.id],
        wsEvents.declarationChange.changed,
        this.declarationChangeCallback,
        true
      );
      this.levelChangeChannel = subscribe(
        "levels",
        [getSubdomain(), this.display.resource.level],
        wsEvents.levelChange.changed,
        this.levelChangeCallback,
        true
      );
    },
    setTemplate() {
      if (this.display && this.display.template) {
        if (this.display.template.toString().endsWith("_BLACK")) {
          this.black = true;
        } else {
          this.black = false;
        }

        if (this.display.template == "DISPLAY_LANDSCAPE_WITH_HORIZONTAL_BAR") {
          this.display.template = 2;
        } else if (
          this.display.template == "DISPLAY_LANDSCAPE_WITH_VERTICAL_BAR"
        ) {
          this.display.template = 1;
        } else if (
          this.display.template ==
            "DISPLAY_LANDSCAPE_WITH_VERTICAL_BAR_AND_NEXT_MEETINGS_HOST" ||
          this.display.template ==
            "DISPLAY_LANDSCAPE_WITH_VERTICAL_BAR_AND_NEXT_MEETINGS_HOST_BLACK"
        ) {
          this.display.template = 4;
        } else if (
          this.display.template ==
            "DISPLAY_LANDSCAPE_WITH_VERTICAL_BAR_AND_NEXT_MEETINGS_TITLE" ||
          this.display.template ==
            "DISPLAY_LANDSCAPE_WITH_VERTICAL_BAR_AND_NEXT_MEETINGS_TITLE_BLACK"
        ) {
          this.display.template = 3;
        } else if (
          this.display.template ==
            "DISPLAY_LANDSCAPE_WITH_VERTICAL_BAR_AND_NEXT_MEETINGS_HOST_AND_TITLE" ||
          this.display.template ==
            "DISPLAY_LANDSCAPE_WITH_VERTICAL_BAR_AND_NEXT_MEETINGS_HOST_AND_TITLE_BLACK"
        ) {
          this.display.template = 5;
        } else if (
          this.display.template ==
          "DISPLAY_LANDSCAPE_WITH_HORIZONTAL_BAR_ANONYMOUS_CHECKIN"
        ) {
          this.display.template = 2;
        } else if (
          this.display.template ==
          "DISPLAY_LANDSCAPE_WITH_VERTICAL_BAR_ANONYMOUS_CHECKIN"
        ) {
          this.display.template = 1;
        } else if (
          this.display.template ==
          "DISPLAY_LANDSCAPE_WITH_VERTICAL_BAR_AND_NEXT_MEETINGS_HOST_ANONYMOUS_CHECKIN"
        ) {
          this.display.template = 4;
        } else if (
          this.display.template ==
          "DISPLAY_LANDSCAPE_WITH_VERTICAL_BAR_AND_NEXT_MEETINGS_TITLE_ANONYMOUS_CHECKIN"
        ) {
          this.display.template = 3;
        } else if (
          this.display.template ==
          "DISPLAY_LANDSCAPE_WITH_VERTICAL_BAR_AND_NEXT_MEETINGS_HOST_AND_TITLE_ANONYMOUS_CHECKIN"
        ) {
          this.display.template = 5;
        } else {
          this.display.template = 1;
        }
      }
    },
    anonymousCheckin() {
      if (
        this.display.anonymous_checkin &&
        this.display.status.code == 2 &&
        this.display.meeting &&
        this.display.meeting.id
      ) {
        httpServiceAuth.get(
          `${apiEndpoints.company.displayAnonymousCheckin}/${this.display.meeting.id}`
        );
      }
    },
    setStatus() {
      this.status = {
        icon: null,
        class: null
      };

      if (this.display && this.display.status) {
        if (this.display.status.code == 2) {
          this.status.class = "checkin";
          this.status.icon = "#cx-vis1-check-in";

          if (this.display.manufacturer) {
            if (this.display.manufacturer == "crestron") {
              try {
                displayCrestron.setOrange();
              } catch (e) {
                // nothing
              }
            }
            if (this.display.manufacturer == "qbic") {
              try {
                displayQbic.setOrange();
              } catch (e) {
                // nothing
              }
            }
            if (this.display.manufacturer == "iadea") {
              try {
                displayIadea.setColor("#FFA500");
              } catch (e) {
                // nothing
              }
            }
          }
        } else if (this.display.status.code == 3) {
          this.status.class = "declined";
          this.status.icon = "#cx-msg1-declined-100x100";

          if (this.display.manufacturer) {
            if (this.display.manufacturer == "crestron") {
              try {
                displayCrestron.setRed();
              } catch (e) {
                // nothing
              }
            }
            if (this.display.manufacturer == "qbic") {
              try {
                displayQbic.setRed();
              } catch (e) {
                // nothing
              }
            }
            if (this.display.manufacturer == "iadea") {
              try {
                displayIadea.setColor("#FF0000");
              } catch (e) {
                // nothing
              }
            }
          }
        } else {
          this.status.class = "accepted";
          this.status.icon = "#cx-msg1-accepted-100x100";

          if (this.display.manufacturer) {
            if (this.display.manufacturer == "crestron") {
              try {
                displayCrestron.setGreen();
              } catch (e) {
                // nothing
              }
            }
            if (this.display.manufacturer == "qbic") {
              try {
                displayQbic.setGreen();
              } catch (e) {
                // nothing
              }
            }
            if (this.display.manufacturer == "iadea") {
              try {
                displayIadea.setColor("#00ff00");
              } catch (e) {
                // nothing
              }
            }
          }
        }
      }
    },
    async getDisplayInfo() {
      let deviceToken = localStorage.getItem("device_token")
        ? localStorage.getItem("device_token")
        : this.generateRandomString(10);

      if (!localStorage.getItem("device_token"))
        localStorage.setItem("device_token", deviceToken);

      return httpServiceAuth.post(
        `${apiEndpoints.company.display}/${this.code}`,
        { device_token: localStorage.getItem("device_token") }
      );
    },
    setDisplaySize() {
      if (
        this.showMap == "hidden" &&
        this.showCalendar == "hidden" &&
        this.showDisplay
      ) {
        // eslint-disable-next-line prettier/prettier
        if ([1, 3, 4, 5].includes(this.display.template)) {
          // eslint-disable-next-line prettier/prettier
          this.$refs.qrcode.style.height = this.$refs.resource.clientHeight + "px";
          // eslint-disable-next-line prettier/prettier
          this.$refs.qrcode.style.width = this.$refs.resource.clientHeight + "px";
          // eslint-disable-next-line prettier/prettier
          this.$refs.status.style.width = this.$refs.resource.clientHeight + "px";
          // eslint-disable-next-line prettier/prettier
          this.$refs.logo.style.height = this.$refs.resource.clientHeight + "px";
          // eslint-disable-next-line prettier/prettier
          this.$refs.logo.style.minWidth = this.$refs.resource.clientHeight + "px";
          // eslint-disable-next-line prettier/prettier
          if (this.display.meeting) this.$refs.times.style.width = this.$refs.times.clientHeight + "px";
          if (this.display.template == 1) {
            // eslint-disable-next-line prettier/prettier
            this.$refs.calendar.style.height =
              this.$refs.logo.clientHeight * 0.6 + "px";
            this.$refs.calendar.style.width =
              this.$refs.calendar.clientHeight + "px";
            // eslint-disable-next-line prettier/prettier
            if (this.display.map) {
              this.$refs.map.style.height =
                this.$refs.logo.clientHeight * 0.6 + "px";
              this.$refs.map.style.width = this.$refs.map.clientHeight + "px";
            }
            // eslint-disable-next-line prettier/prettier
            if (this.display.video) {
              this.$refs.video.style.height =
                this.$refs.logo.clientHeight * 0.6 + "px";
              this.$refs.video.style.width =
                this.$refs.video.clientHeight + "px";
            }

            if (this.display.videoconference) {
              this.$refs.videoconference.style.height =
                this.$refs.logo.clientHeight * 0.6 + "px";
              this.$refs.videoconference.style.width =
                this.$refs.videoconference.clientHeight + "px";
            }
          }
          if ([3, 4, 5].includes(this.display.template)) {
            // eslint-disable-next-line prettier/prettier
            this.$refs.nextmeetings.style.height = this.$refs.resource.clientHeight + "px";
          }
        }

        if (this.black == true) {
          this.$refs.statuswhite.style.height =
            this.$refs.logo.clientHeight / 2 + "px";
        }
      }
    },
    async displayHeartbeat() {
      this.heartbeat();
      let self = this;
      setInterval(function() {
        self.heartbeat();
      }, 90000);
    },
    heartbeat() {
      httpServiceAuth.get(
        `${apiEndpoints.company.displayHeartbeat}/${this.display.resource.id}`
      );
    },
    async getMapDateTime() {
      return httpServiceAuth.get(
        `${apiEndpoints.company.displayMapDateTime}/${this.display.map}?lang=${this.display.language}`
      );
    },
    async setMapDateTime() {
      let mapDateTime = await this.getMapDateTime();
      this.mapDate = mapDateTime.data.date;
      this.mapFrom = mapDateTime.data.from;
      this.mapTo = mapDateTime.data.to;
    },
    async connectorChangeCallback() {
      this.showMainDisplay();
      await this.initialize();
    },
    async companyProfileCallback() {
      this.showMainDisplay();
      await this.initialize();
    },
    async resourceChangeCallback() {
      this.showMainDisplay();
      await this.initialize();
    },
    async declarationChangeCallback() {
      await this.initialize();
    },
    async levelChangeCallback() {
      this.showMainDisplay();
      await this.initialize();
    },
    showMainDisplay() {
      this.setScreensaver();
      this.showDisplay = true;
      this.showVideo = false;
      this.showVideoConference = false;
      this.showMap = "hidden";
      this.showCalendar = "hidden";
      this.$nextTick(function() {
        this.setDisplaySize();
      });
    },
    showCalendarDisplay() {
      this.setScreensaver();
      this.showCalendar = "visible";
      this.showDisplay = false;
      this.showVideo = false;
      this.showVideoConference = false;
      this.showMap = "hidden";
    },
    showMapDisplay() {
      this.setScreensaver();
      this.showDisplay = false;
      this.showVideo = false;
      this.showVideoConference = false;
      this.showMap = "visible";
      this.showCalendar = "hidden";
      this.setMapDateTime();
      if (!this.mapIframe) this.setMapUrl();
      else {
        this.$refs.mapIframe.contentWindow.postMessage(
          {
            call: "displayMapChanged",
            value: ""
          },
          "*"
        );
      }
    },
    setMapUrl() {
      const subdomain = getSubdomain();
      this.mapUrl = `${process.env.VUE_APP_5DMAPS_URL}/display/${
        this.display.map
      }?api=${this.getMapApi()}&subdomain=${subdomain}&type=display&lang=${
        this.display.language
      }`;
      this.mapIframe = true;
    },
    showVideoDisplay() {
      clearInterval(this.screensaverInterval);
      this.showDisplay = false;
      this.showVideoConference = false;
      this.showMap = "hidden";
      this.showCalendar = "hidden";
      this.showVideo = true;
      this.setVideoUrl();
    },
    setVideoUrl() {
      this.videoUrl = this.display.video.replace("watch?v=", "");
      if (this.videoUrl.includes("&")) {
        this.videoUrl = this.videoUrl.split("&")[0];
      }
      this.videoUrl =
        "https://www.youtube.com/embed/" +
        this.videoUrl +
        "?controls=0&amp;start=1&amp;&autoplay=1&amp;loop=1&amp;rel=0&amp;playlist=" +
        this.videoUrl;
    },
    showVideoConferenceDisplay() {
      clearInterval(this.screensaverInterval);
      this.showDisplay = false;
      this.showMap = "hidden";
      this.showCalendar = "hidden";
      this.showVideo = false;
      this.showVideoConference = true;
    },
    setVideoconfrenceUrl() {
      this.videoConferenceUrl = this.display.videoconference;
    },
    setScreensaver() {
      if (this.display.screensaver && this.display.video) {
        if (this.screensaverInterval) clearInterval(this.screensaverInterval);
        this.screensaver = this.display.screensaver;
        let self = this;
        this.screensaverInterval = setInterval(function() {
          self.screensaver--;
          if (self.screensaver < 0) {
            self.showVideoDisplay();
          }
        }, 1000);
      } else {
        clearInterval(this.screensaverInterval);
      }
    },
    generateRandomString(length) {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
    startVideoconference() {
      window.location.href = this.display.videoconference;
    }
  },
  beforeUnmount() {
    this.setScreensaver();
    if (this.connectorChangeChannel) {
      unsubscribe(this.connectorChangeChannel);
      this.connectorChangeChannel = null;
    }
    if (this.companyProfileChannel) {
      unsubscribe(this.companyProfileChannel);
      this.companyProfileChannel = null;
    }
    if (this.resourceChangeChannel) {
      unsubscribe(this.resourceChangeChannel);
      this.resourceChangeChannel = null;
    }
    if (this.declarationChangeChannel) {
      unsubscribe(this.declarationChangeChannel);
      this.declarationChangeChannel = null;
    }
    if (this.levelChangeChannel) {
      unsubscribe(this.levelChangeChannel);
      this.levelChangeChannel = null;
    }
  }
};
</script>
